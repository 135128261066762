import { useEffect } from 'react';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { GTM_ID } from '../config/env';
export function SetupGtm() {
  var router = useRouter();
  useEffect(function () {
    if (true && typeof document !== "undefined") {
      TagManager.initialize({
        gtmId: GTM_ID
      });
    }
  }, []);
  useEffect(function () {
    if (router.asPath && router.pathname) {
      TagManager.dataLayer({
        dataLayer: {
          'event': "gtm.historyChange",
          'gtm.historyChangeSource': 'pushState',
          'gtm.newUrl': router.asPath,
          'path': router.pathname,
          'newHistoryState': {
            'url': window.location.href,
            'options': {
              'locale': router.locale ? router.locale : 'pt'
            }
          }
        }
      });
    }
  }, [router.pathname, router.asPath, router.locale]);
  return null;
}