// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
var SENTRY_DSN = process.env.CUSTOMER_SENTRY_DSN || process.env.NEXT_PUBLIC_CUSTOMER_SENTRY_DSN;
var ENV = process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT;
Sentry.init({
  dsn: SENTRY_DSN || 'https://d45d35890c334a0782b41c3dc0c51a34@sentry.after.sale/47',
  environment: ENV // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

});