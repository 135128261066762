import { extendTheme } from '@chakra-ui/react';
export var theme = extendTheme({
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat'
  },
  fontSizes: {
    'title': '24px',
    'label': '12px'
  },
  colors: {
    gray: {
      'bg': '#F2F2F2',
      'light': '#FDFDFD',
      'border': '#EAEAEA'
    },
    text: {
      'secondary': '#686868',
      'primary': '#2C2929'
    },
    purple: {
      'primary': '#7041EE'
    }
  }
});