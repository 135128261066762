var createValueGetter = function createValueGetter(data) {
  return function () {
    return data;
  };
};

var createCast = function createCast(data) {
  return function () {
    var obj = {};

    for (var _len = arguments.length, fields = new Array(_len), _key = 0; _key < _len; _key++) {
      fields[_key] = arguments[_key];
    }

    fields.forEach(function (field) {
      if (field in data) obj[field] = data[field];
    });
    return pipethrough(obj);
  };
};

var createPipe = function createPipe(data) {
  return function (fn) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    if (data instanceof Promise) {
      return pipethrough(data.then(function (d) {
        return fn.apply(void 0, [d].concat(args));
      }));
    }

    return pipethrough(fn.apply(void 0, [data].concat(args)));
  };
};

var createCasePipe = function createCasePipe(data) {
  return function (pred, fn) {
    for (var _len3 = arguments.length, args = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
      args[_key3 - 2] = arguments[_key3];
    }

    if (data instanceof Promise) {
      return pipethrough(data.then(function (d) {
        if (pred(d)) return fn.apply(void 0, [d].concat(args));
        return d;
      }));
    }

    if (pred(data)) return pipethrough(fn.apply(void 0, [data].concat(args)));
    return pipethrough(data);
  };
};

export function pipethrough(data) {
  return {
    pipe: createPipe(data),
    casePipe: createCasePipe(data),
    cast: createCast(data),
    value: createValueGetter(data)
  };
}