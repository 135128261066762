import { useRouter } from 'next/router';
export var useGoToDisabled = function useGoToDisabled() {
  var _useRouter = useRouter(),
      push = _useRouter.push;

  var pushToEcommerceDisabled = function pushToEcommerceDisabled() {
    return push('/ecommerce-disabled');
  };

  return pushToEcommerceDisabled;
};