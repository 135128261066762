import { DEV_HOST, NODE_ENV } from '../config/env';
export var DEFAULT_DEV_HOST = 'teste.sprint.delivery.after.sale';
export var getHost = function getHost() {
  if (false) return undefined;

  if (NODE_ENV === 'development') {
    return DEV_HOST || DEFAULT_DEV_HOST;
  }

  return window.location.host;
};