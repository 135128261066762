import _defineProperty from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useEffect } from 'react';
import { getHost } from '../config';
import { pipethrough } from '../pipethrough';
import { stripPort } from '../string-utils';
import { useEcommerceSettingsQuery } from '@aftersale-next/graphql';
import { useRouter } from 'next/router';
import { useGoToNotFound } from './redirects/use-go-to-not-found';
import { useGoToDisabled } from './redirects/use-go-to-ecommerce-disabled';
export default function useEcommerceSettings() {
  var _result$data;

  var router = useRouter();
  var host = pipethrough(getHost()).pipe(stripPort).value();
  var pushToNotFound = useGoToNotFound();
  var pushToEcommerceDisabled = useGoToDisabled();
  var result = useEcommerceSettingsQuery({
    host: host
  }, {
    refetchOnWindowFocus: false
  });
  useEffect(function () {
    var _result$data$settings;

    if (router.pathname === '/404' || router.pathname === '/not-found') return;

    if (result.error) {
      pushToNotFound();
      return;
    }

    if (!result.data) return;

    if (!((_result$data$settings = result.data.settings) !== null && _result$data$settings !== void 0 && _result$data$settings.enabled) && router.pathname !== '/ecommerce-disabled') {
      pushToEcommerceDisabled();
    }
  }, [router, result.error, result.data]);
  return _objectSpread(_objectSpread({}, result), {}, {
    data: (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.settings
  });
}