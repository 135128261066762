import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEcommerceEnabled, useEcommerceName, useEcommerceUrlSettings, useEcommerceImages, useEcommerceConfirmationType, useEcommerceFeatures, useIsFetchingEcommerce, usePoolingInterval } from '@aftersale-next/atoms';
import { useEffect } from 'react';
import useEcommerceSettings from '../use-ecommerce-settings';
var TEN_SECONDS = 10 * 1000;

var useSetupEcommerceAtoms = function useSetupEcommerceAtoms() {
  var _useEcommerceEnabled = useEcommerceEnabled(),
      _useEcommerceEnabled2 = _slicedToArray(_useEcommerceEnabled, 2),
      setEcommerceEnabled = _useEcommerceEnabled2[1];

  var _useEcommerceName = useEcommerceName(),
      _useEcommerceName2 = _slicedToArray(_useEcommerceName, 2),
      setEcommerceName = _useEcommerceName2[1];

  var _useEcommerceUrlSetti = useEcommerceUrlSettings(),
      _useEcommerceUrlSetti2 = _slicedToArray(_useEcommerceUrlSetti, 2),
      setEcommerceUrlSettings = _useEcommerceUrlSetti2[1];

  var _useEcommerceImages = useEcommerceImages(),
      _useEcommerceImages2 = _slicedToArray(_useEcommerceImages, 2),
      setEcommerceImages = _useEcommerceImages2[1];

  var _useEcommerceConfirma = useEcommerceConfirmationType(),
      _useEcommerceConfirma2 = _slicedToArray(_useEcommerceConfirma, 2),
      setEcommerceConfirmation = _useEcommerceConfirma2[1];

  var _useEcommerceFeatures = useEcommerceFeatures(),
      _useEcommerceFeatures2 = _slicedToArray(_useEcommerceFeatures, 2),
      setEcommerceFeatures = _useEcommerceFeatures2[1];

  var _useIsFetchingEcommer = useIsFetchingEcommerce(),
      _useIsFetchingEcommer2 = _slicedToArray(_useIsFetchingEcommer, 2),
      setIsFetchingEcommerce = _useIsFetchingEcommer2[1];

  var _usePoolingInterval = usePoolingInterval(),
      _usePoolingInterval2 = _slicedToArray(_usePoolingInterval, 2),
      setPooling = _usePoolingInterval2[1];

  var settings = useEcommerceSettings();
  useEffect(function () {
    var _settings$data, _settings$data2, _settings$data3, _settings$data4, _settings$data5, _settings$data6, _settings$data$url, _settings$data7, _settings$data$utm$ut, _settings$data8, _settings$data8$utm, _settings$data$utm$ut2, _settings$data9, _settings$data9$utm;

    if ((_settings$data = settings.data) !== null && _settings$data !== void 0 && _settings$data.demo) {
      setPooling(TEN_SECONDS);
    }

    setEcommerceEnabled(!!((_settings$data2 = settings.data) !== null && _settings$data2 !== void 0 && _settings$data2.enabled));
    setEcommerceName(((_settings$data3 = settings.data) === null || _settings$data3 === void 0 ? void 0 : _settings$data3.name) || '');
    setEcommerceImages(((_settings$data4 = settings.data) === null || _settings$data4 === void 0 ? void 0 : _settings$data4.images) || []);
    setEcommerceConfirmation((_settings$data5 = settings.data) === null || _settings$data5 === void 0 ? void 0 : _settings$data5.confirmation_type);
    setEcommerceFeatures(((_settings$data6 = settings.data) === null || _settings$data6 === void 0 ? void 0 : _settings$data6.features) || []);
    setEcommerceUrlSettings({
      url: (_settings$data$url = (_settings$data7 = settings.data) === null || _settings$data7 === void 0 ? void 0 : _settings$data7.url) !== null && _settings$data$url !== void 0 ? _settings$data$url : '',
      utm: {
        utmMainLogo: (_settings$data$utm$ut = (_settings$data8 = settings.data) === null || _settings$data8 === void 0 ? void 0 : (_settings$data8$utm = _settings$data8.utm) === null || _settings$data8$utm === void 0 ? void 0 : _settings$data8$utm.utmMainLogo) !== null && _settings$data$utm$ut !== void 0 ? _settings$data$utm$ut : '',
        utmCoupon: (_settings$data$utm$ut2 = (_settings$data9 = settings.data) === null || _settings$data9 === void 0 ? void 0 : (_settings$data9$utm = _settings$data9.utm) === null || _settings$data9$utm === void 0 ? void 0 : _settings$data9$utm.utmCoupon) !== null && _settings$data$utm$ut2 !== void 0 ? _settings$data$utm$ut2 : ''
      }
    });
  }, [setEcommerceConfirmation, setEcommerceEnabled, setEcommerceFeatures, setEcommerceImages, setEcommerceName, setEcommerceUrlSettings, setPooling, settings.data]);
  useEffect(function () {
    setIsFetchingEcommerce(settings.isLoading);
  }, [settings.isLoading, setIsFetchingEcommerce]);
};

export default useSetupEcommerceAtoms;