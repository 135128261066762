import { useRouter } from 'next/router';
export var useGoToNotFound = function useGoToNotFound() {
  var _useRouter = useRouter(),
      push = _useRouter.push;

  var pushToNotFound = function pushToNotFound() {
    return push('/not-found');
  };

  return pushToNotFound;
};